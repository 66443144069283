/* index.css */

/* Reset some default browser styles */
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
  
  /* Custom global styles */
  html, body, #root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  /* Additional global styles */
  a {
    text-decoration: none;
    color: inherit;
  }
  
  * {
    box-sizing: border-box;
  }
  